import React, { useMemo, useState, useEffect } from "react";
import axios from 'axios';
import { COLUMNS, SetTableActionPermission } from "./columns";
import Table from "./Table";
import { useLocation } from "react-router-dom";
import { ResponseMsg } from './ResponseMsg';
import { properties } from "../assets/properties";
import {env} from '../env';

export function HomeContent(props) {

  const baseURL = env.REACT_APP_BASE_URL;
  const [clientData, setClientApps] = useState([]);  
  const columns = useMemo(() => COLUMNS, []);
  const location = useLocation();
  const [msg, setMsg] = useState({});
  const hiddenColumns = props.hiddenColumns;
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);

  const getClientAppData = async () => {
    let relativePath = "";
    if(props.role === properties.dbRoleList.CLIENT_ADMIN) {
      relativePath = "/v1/clients/search?ssoId=" + props.ssoId;
    } else {
      relativePath = "/v1/clients";
    }
    try {
      const clientDataRes = await axios.get(baseURL + relativePath,
        {
          headers: {
            "Authorization": 'Bearer ' + props.tokenId
          }
        })
        if(props.role === properties.dbRoleList.CLIENT_ADMIN){
          clientDataRes.data = clientDataRes.data.flatMap(obj => (properties.editAppStatusList.includes(obj.status)) ? obj : []);
        }
      setClientApps(clientDataRes.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setShowError(true);
    }
  }

  useEffect(() => {
    if (props.tokenId !== null && props.role !== undefined && props.ssoId !== undefined) {
      getClientAppData();
    }
  }, [props.tokenId, props.role, props.ssoId]);

  useEffect(() => {
    if(location.state !== null && location.state !== undefined){
      setMsg(location.state);
    } 
 }, [location]);

  return (
    <>
    <ResponseMsg msg={msg}/>
    <div className="homeContainer">
      <SetTableActionPermission />
      <Table classValList="nctable" columns={columns} data={clientData} hiddenColumns={hiddenColumns} loading={loading} showError={showError}/>
    </div>
    </>
  );
}