import { useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { properties } from '../assets/properties';
import { env } from '../env';
import { dynamicPMCHelper } from '../Helpers/dynamicPMCHelper';

export const useForm = (initialValues, validate, tokenId, submitUrl, cancelRegisterUrl) => {
    const [inputs, setInputs] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [actionResp, setActionResp] = useState({});
    const [isTargetMandatory, setIsTargetMandatory] = useState(false);
    const history = useHistory();
    const baseURL = env.REACT_APP_BASE_URL;
    const submitUrlVal = baseURL + submitUrl;
    const [confirmMsg, setConfirmMsg] = useState("approve");

    const updateData = (data) => {
        setInputs(data);
    }

    const handleSubmit = (event, methodType, successMsg, redirectToHome) => {
        event.preventDefault();
        const validationErrors = validate(inputs);
        const noErrors = Object.keys(validationErrors).length === 0;
        setErrors(validationErrors);
        setActionResp({ success: false, msg: "" });
        if (noErrors) {
            let appStorage = window.localStorage;
            let loggedInUser = appStorage.getItem('loggedInUser');
            inputs.requesterUserId = loggedInUser;
            const headers = {
                "Authorization": 'Bearer ' + tokenId
            };
            if (inputs.dynamicPaymentMethodConfigurations) {
                const dynamicPaymentMethodConfigs = dynamicPMCHelper(inputs.dynamicPaymentMethodConfigurations);
                inputs.dynamicPaymentMethodConfigurations = dynamicPaymentMethodConfigs;
            }
            else if (!inputs.dynamicPaymentMethodConfigurations && "dynamicPaymentMethodConfigurations" in inputs) {
                delete inputs.dynamicPaymentMethodConfigurations;
            }
            if (inputs.paymentDTOs && inputs.paymentDTOs.length > 0) {
                inputs.paymentDTOs.forEach((item) => {
                    if (item.id) {
                        item.id = item.id.toString().replace("chk_", "");
                    }
                })
            }
            if ("name" in inputs) {
                successMsg = successMsg.replace("{clientAppName}", inputs.name);
            }
            if (methodType === properties.methodList.POST) {
                axios.post(submitUrlVal, inputs, { headers })
                    .then(response => {
                        if (response.data.userId) {
                            delete inputs.requesterUserId;
                            let resetInput = {};
                            Object.entries(inputs).forEach(([key, value], i) => {
                                resetInput[key] = "";
                            });
                            setInputs(resetInput);
                            if (redirectToHome) {
                                history.push({
                                    pathname: '/',
                                    state: { success: true, msg: successMsg }
                                });
                            } else {
                                setActionResp({ success: true, msg: successMsg });
                            }
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            delete inputs.requesterUserId;
                            setActionResp({ success: false, msg: error.response.data.errorMessage.toLowerCase() });
                        }
                    });
            } else if (methodType === properties.methodList.PUT) {
                axios.put(submitUrlVal, inputs, { headers })
                    .then(response => {
                        history.push({
                            pathname: '/',
                            state: { success: true, msg: successMsg }
                        });
                    })
                    .catch(error => {
                        if (error.response) {
                            delete inputs.requesterUserId;
                            setActionResp({ success: false, msg: error.response.data.errorMessage.toLowerCase() });
                        }
                    });
            }
        }
    }
    const handleInputChange = (event) => {
        event.persist();
        event.target.classList.remove("input-error");
        const closestParent = document.getElementById(event.target.id).closest('.input-error');
        if (closestParent !== null) {
            closestParent.classList.remove('input-error');
        }
        // const e = document.getElementById(event.target.id);
        // const closet = e.closest("div").querySelector(".input-err-msg");
        // if(closet !== null){
        //     closet.remove();
        // }
        if (event.target.id.toLowerCase() === properties.FormFieldNames.type.fieldName.toLocaleLowerCase()) {
            const e = document.getElementById(event.target.id);
            if ((e.value === properties.typeList.SOAP) || (e.value === properties.typeList.PUBLIC)) {
                setIsTargetMandatory(false);
                properties.FormFieldNames.targetUrl.required = false;
                document.getElementById(properties.FormFieldNames.targetUrl.name).classList.remove("input-error");
                properties.FormFieldNames.template.required = false;
                document.getElementById(properties.FormFieldNames.template.name).classList.remove("input-error");
            } else {
                setIsTargetMandatory(true);
                properties.FormFieldNames.targetUrl.required = true;
                properties.FormFieldNames.template.required = true;
            }
        }
        const inputData = isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value);
        setErrors(errors => ({ ...errors, [event.target.name]: "" }));
        setInputs(inputs => ({ ...inputs, [event.target.name]: inputData }));
    }
    const cancelRegister = () => {
        if (cancelRegisterUrl === "goback") {
            history.goBack();
        }
        else if (cancelRegisterUrl === "gotopromote") {
            history.push({
                pathname: '/my-worklist',
                state: { promote: 2, msg: "" }
            });
        }
        else {
            history.push(cancelRegisterUrl);
        }
    }
    const openModal = (e) => {
        var cm = e.target.getAttribute("data-confirm");
        setConfirmMsg(cm);
        var modal = document.getElementById("confirmModal");
        modal.style.display = "block";
    }
    return {
        inputs,
        errors,
        actionResp,
        confirmMsg,
        handleSubmit,
        handleInputChange,
        cancelRegister,
        updateData,
        openModal,
        isTargetMandatory,
        setIsTargetMandatory
    };
}

// function getCookie(cname) {
//     var name = cname + "=";
//     var decodedCookie = decodeURIComponent(document.cookie);
//     var ca = decodedCookie.split(';');
//     for(var i = 0; i < ca.length; i++) {
//         var c = ca[i];
//         while (c.charAt(0) === ' ') {
//         c = c.substring(1);
//         }
//         if (c.indexOf(name) === 0) {
//         return c.substring(name.length, c.length);
//         }
//     }
//     return "";
// }