import React, { useMemo, useState, useEffect } from "react";
import axios from 'axios';
import { COLUMNS, SetTableActionPermission } from "./columns";
import Table from "./Table";
import { useLocation } from "react-router-dom";
import { ResponseMsg } from './ResponseMsg';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { properties } from "../assets/properties";
import {env} from '../env';

export function MyWorkList(props){
  const baseURL = env.REACT_APP_BASE_URL;
  const [clientData, setClientApps] = useState([]); 
  const [promoData, setPromoApps] = useState([]);  
  const columns = useMemo(() => COLUMNS, []);
  const location = useLocation();
  const [msg, setMsg] = useState({});
  const [WPSAdmins, setWPSAdmins] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showPromoteClientTabError, setShowPromoteClientTabError] = useState(false);
  const [promoTabLoading, setPromoTabLoading] = useState(true);
  let myWorklistHiddenColumns = props.myWorklistProps.myWorklistHiddenColumns, showPromotoToProdTab = props.myWorklistProps.showPromotoToProdTab;
  
  const getClientAppData = async () => {
    try {
      const clientDataRes = await axios.get(baseURL + properties.apiList.getClientAppByParamsURL.replace('{searchParams}','status=AWAITING_ACTIVATION'),
        {
          headers: {
            "Authorization": 'Bearer ' + props.tokenId
          }
        })
      setClientApps(clientDataRes.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setShowError(true);
    }
  }

  const getPromoteClientAppData = async () => {
    try {
      const promoDataRes = await axios.get(baseURL + properties.apiList.fetchPromoteAppURL,
        {
          headers: {
            "Authorization": 'Bearer ' + props.tokenId
          }
        })
      setPromoApps(promoDataRes.data);
      setPromoTabLoading(false);
    }
    catch (e) {
      setPromoTabLoading(false);
      setShowPromoteClientTabError(true);
    }
  }

  useEffect(() => {
    if (props.tokenId !== null) {
      getClientAppData();
      getPromoteClientAppData();
      if(!showPromotoToProdTab){
        fetchWPSAdmins();
      }
    }
  }, [props.tokenId]);

  useEffect(() => {
    if(location.state !== null && location.state !== undefined){
      setMsg(location.state);
      if(location.state.promote){
        setSelectedTab(1);
      }
    } 
 }, [location]);

 const fetchWPSAdmins = async () => {
   try{
    const WPSAdminRes = await axios.get(baseURL + properties.apiList.fetchClientAdmins.replace("{adminRole}", "WPS_Admin"),
        {
            headers: {
                "Authorization": 'Bearer ' + props.tokenId
            }
        }
    )
    const wpsAdminArr = WPSAdminRes.data.slice(0, 3).map(el => {
        return el.email
    })
    setWPSAdmins(wpsAdminArr.join(", "));
  }catch(e){
    setShowError(true);
  }
}

    return (
        <>
        <ResponseMsg msg={msg}/>
        <div className="wrapper">
            <div className="my-worklist">
                <SetTableActionPermission adminArr={WPSAdmins} activeTab={selectedTab}/>
                {showPromotoToProdTab ? 
                <Tabs selectedIndex={selectedTab} onSelect={index => setSelectedTab(index)}>
                    <TabList>
                    <Tab>{properties.pageHeaders.worklistTabHeader}</Tab>
                    <Tab>{properties.pageHeaders.promoteToProdTabHeader}</Tab>
                    </TabList>

                    <TabPanel>
                        <Table classValList="nctable worklist" columns={columns} data={clientData} hiddenColumns={myWorklistHiddenColumns} loading={loading} showError={showError}/>
                    </TabPanel>
                    <TabPanel>
                        <Table classValList="nctable worklist" columns={columns} data={promoData} hiddenColumns={myWorklistHiddenColumns} loading={promoTabLoading} showError={showPromoteClientTabError}/>
                    </TabPanel>
                </Tabs> :
                <Table classValList="nctable" columns={columns} data={clientData} hiddenColumns={myWorklistHiddenColumns} loading={loading} showError={showError}/>
            }
            </div>
        </div>
        </>
    );
}