import React, { useEffect, useState } from 'react';

export const PaymentMethodAccordion = ({ paymentMethodList, appId, disabled, onChangePaymentMethod, inputPaymentsData }) => {
    const [togglePaymentMethodList, setTogglePaymentMethodList] = useState([]);
    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState();
    const [showAccordion, setShowAccordion] = useState(false);
    const [uniqueCategoryPerRegion, setUniqueCategoryPerRegion] = useState([]);
    var toggleArr = paymentMethodList;

    useEffect(() => {
        if (paymentMethodList.length > 0) {
            setTogglePaymentMethodList(paymentMethodList);

            const uniqueRegions = [
                ...new Map(paymentMethodList.map((item) => [item["regionId"], item])).values()
            ];
            setRegions(uniqueRegions);
            setSelectedRegion(paymentMethodList[0].regionId);
            let selectedRegionPayments = paymentMethodList.filter(item => (item.regionId.toString() === paymentMethodList[0].regionId.toString()));
            let uniqueCategoryPerRegionVar = [];
            for (var i = 0; i < selectedRegionPayments.length; i++) {
                let category = selectedRegionPayments[i].paymentCategory;
                if (uniqueCategoryPerRegionVar.findIndex(a => a.paymentCategory === category) <= -1) {
                    uniqueCategoryPerRegionVar.push(selectedRegionPayments[i]);
                }
            }
            setUniqueCategoryPerRegion(uniqueCategoryPerRegionVar);
            if (!appId) {
                inputPaymentsData = [...paymentMethodList];
                toggleArr.forEach(item => {
                    item.showPlusIcon = false;
                });
                setTogglePaymentMethodList((prev) => ({ ...prev, toggleArr }));
            }
            else {
                uniqueRegions.forEach((region) => {
                    let selectedRegionPayments = paymentMethodList.filter(item => (item.regionId.toString() === region.regionId.toString()));
                    let uniqueCategoryPerRegionVar = [];
                    for (var i = 0; i < selectedRegionPayments.length; i++) {
                        let category = selectedRegionPayments[i].paymentCategory;
                        if (uniqueCategoryPerRegionVar.findIndex(a => a.paymentCategory === category) <= -1) {
                            uniqueCategoryPerRegionVar.push(selectedRegionPayments[i]);
                        }
                    }
                    uniqueCategoryPerRegionVar.forEach((category) => {
                        toggleArr.filter((item1) => ((item1.paymentCategory === category.paymentCategory) && (item1.regionId.toString() === category.regionId.toString()))).forEach((item2) => {
                            item2.showPlusIcon = true;
                        })
                    })
                })
                inputPaymentsData.forEach(item => {
                    toggleArr.filter(a => (a.regionId.toString() === item.regionId.toString()) && (a.paymentCategory === item.paymentCategory)).forEach((item1) => {
                        item1.showPlusIcon = false;
                    })
                });
                setTogglePaymentMethodList((prev) => ({ ...prev, toggleArr }));
            }
            setShowAccordion(true);
        }
        else {
            setShowAccordion(false);
        }
    }, [])

    const onChangeRegion = (event) => {
        setSelectedRegion(event.target.id);
        let uniqueCategoryPerRegionVar = [];
        let selectedRegionPayments = paymentMethodList.filter(item => (item.regionId.toString() === event.target.id.toString()));
        for (var i = 0; i < selectedRegionPayments.length; i++) {
            let category = selectedRegionPayments[i].paymentCategory;
            if (uniqueCategoryPerRegionVar.findIndex(a => a.paymentCategory === category) <= -1) {
                uniqueCategoryPerRegionVar.push(selectedRegionPayments[i]);
            }
        }
        setUniqueCategoryPerRegion(uniqueCategoryPerRegionVar);
    }

    const handlePaymentsItemChange = (item, e) => {
        if ((inputPaymentsData.length > 0) && (inputPaymentsData.find(a => a.id.toString() === item.id.toString()))) {
            if (e.target.checked) {
                inputPaymentsData.find(a => a.id.toString() === item.id.toString()).checked = true;
            }
            else {
                inputPaymentsData.find(a => a.id.toString() === item.id.toString()).checked = false;
            }
            let a = inputPaymentsData;
            onChangePaymentMethod(a.filter(a => a.checked === true), e);
        }
    }

    const onClickToggleAccordionTitle = (index) => {
        let selectedCategory = paymentMethodList[index].paymentCategory;
        toggleArr.filter(a => ((a.regionId.toString() == selectedRegion.toString()) && (a.paymentCategory === selectedCategory))).forEach(item => {
            item.showPlusIcon = !item.showPlusIcon;
        });
        setTogglePaymentMethodList((prev) => ({ ...prev, toggleArr }));
    }

    const showPaymentCategoryTitle = (id) => {
        return (uniqueCategoryPerRegion.findIndex(a => a.id.toString() === id.toString()) > -1) ? true : false;
    }

    const getClass = (index, itemId) => {
        if (!showPaymentCategoryTitle(itemId)) {
            if (togglePaymentMethodList[index].showPlusIcon) {
                let ids = "accordion-item_" + itemId;
                if (document.getElementById(ids)) {
                    document.getElementById(ids).style.padding = 0;
                }
            }
            else {
                let ids = "accordion-item_" + itemId;
                if (document.getElementById(ids)) {
                    document.getElementById(ids).style.padding = "8px 12px";
                }
            }
            return "AAA";
        }
        return "BB";
    }

    return (
        <div className="region-container">
            <ul className="region">
                {regions.map(({ regionId, regionSpecificName }, index) => (
                    <li className={`${regionId == selectedRegion ? "active" : ""}`} id={regionId} key={regionId} onClick={onChangeRegion}>{regionSpecificName}</li>
                ))}
            </ul>
            <div className="accordion-box">
                <p className="accordion-title">Funds for this region will be settled to bank accounts associated in Wiley Inc. legal entity.</p>
                {showAccordion && (
                    <div className="accordion form-control" id="paymentDTOs">
                        {paymentMethodList.map((item, index) => (
                            <div key={index} className={`accordion-item ${showPaymentCategoryTitle(item.id) ? "border" : ""}`} id={"accordion-item" + "_" + item.id}>
                                {(item.regionId.toString() === selectedRegion.toString()) && (
                                    <>
                                        <div id={'accordion-title' + '_' + item.id} className={`accordion-title ${showPaymentCategoryTitle(item.id) ? "" : "hide"}`} onClick={() => onClickToggleAccordionTitle(index)}>
                                            <span id={item.regionId + "_" + item.paymentCategory} className={`accordionIcon ${togglePaymentMethodList[index].showPlusIcon ? "accordionPlus" : "accordionMinus"}`}></span>
                                            <span className="accordion-label">{item.paymentCategory}</span>
                                        </div>
                                        <div className={`${getClass(index, item.id)}`}>AAA</div>
                                        <div id={"accordion-content" + "_" + item.id} className={`accordion-content ${togglePaymentMethodList[index].showPlusIcon ? "" : "open"}`}>
                                            <label htmlFor={item.id.toString()} className="checkboxContainer" key={"chk" + item.id} onClick={(e) => {
                                                e.persist = () => { }
                                            }}>
                                                {item.paymentMethodName}
                                                <input type="checkbox" id={item.id.toString()} disabled={disabled} name={item.id.toString()} value={item.id.toString()} className="accordionCheckBox"
                                                    defaultChecked={(inputPaymentsData && inputPaymentsData.find(a => a.id.toString() === item.id.toString())?.checked) ? true : false} onChange={(e) => {
                                                        e.persist = () => { }
                                                        handlePaymentsItemChange(item, e);
                                                    }} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

