import { useState } from "react";
import "../css/tooltip.css"

export const Tooltip = ({ text, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const handleMouseEnter = () => {
        setShowTooltip(true);
    }

    const handleMouseLeave = () => {
        setShowTooltip(false);
    }

    return (
        <span className="tooltip-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {showTooltip && <span className="tooltip">{text}</span>}
        </span>
    )
}