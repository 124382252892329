import React from 'react';
import { useHistory } from "react-router-dom";
import { properties } from "../assets/properties";
import axios from 'axios';
import {env} from '../env';
import { dynamicPMCHelper } from '../Helpers/dynamicPMCHelper';

export function ConfirmDialogbox(props) {
    const history = useHistory();
    const tokenId = props.tokenId;
    let appName = props.appData.name;
    const approve = (props.confirmMsg === "approve") ? true : false;
    let promote = (props.confirmMsg === properties.PROMOTE) ? true : false;
    let adMsg = props.confirmMsg.replace(" ", "") + 'ClientAppSuccessMsg';
    let successMsg = properties.messageList[adMsg].replace("{clientAppName}", appName);
    function closeModal() {
        document.getElementById("confirmModal").style.display = "none";
    }
    function confirmModal() {
        const baseURL = env.REACT_APP_BASE_URL;
        let url = "";
        if (props.confirmMsg === properties.REVERT) {
            url = baseURL + properties.apiList.rollbackClientApplicationUrl.replace("{clientPromotionId}", props.promotionId);
        }else if (promote) {
            url = baseURL + properties.apiList.promoteClientAppURL.replace("{clientId}", props.appId);
        } else {
            url = baseURL + properties.apiList.approveDeclineClientAppURL.replace("{clientId}", props.appId);
        }
        const headers = {
            "Authorization": 'Bearer ' + tokenId
        };
        let appStorage = window.localStorage;
        let loggedInUser = appStorage.getItem('loggedInUser');
        let inputs = props.appData;
        if(promote || (props.confirmMsg === properties.REVERT)){
            inputs = {};
        }
        else if (props.confirmMsg !== properties.REVERT) {
            if (approve)
                inputs.status = properties.statusList.ACTIVE;
            else
                inputs.status = properties.statusList.DECLINED;
        }
        inputs.requesterUserId = loggedInUser;
        if (inputs.dynamicPaymentMethodConfigurations) {
            const dynamicPaymentMethodConfigs = dynamicPMCHelper(inputs.dynamicPaymentMethodConfigurations);
            inputs.dynamicPaymentMethodConfigurations = dynamicPaymentMethodConfigs;
        }
        else if (!inputs.dynamicPaymentMethodConfigurations && "dynamicPaymentMethodConfigurations" in inputs) {
            delete inputs.dynamicPaymentMethodConfigurations;
        }
        //delete inputs.secret;
        axios.put(url, inputs, { headers })
            .then(response => {
                history.push({
                    pathname: props.confirmMsg !== properties.REVERT ? '/my-worklist' : "/",
                    state: { success: (props.confirmMsg === properties.APPROVE || props.confirmMsg === properties.DECLINE) ? approve : true, promote: promote, msg: successMsg }
                });
            })
            .catch(error => {

            });
    }
    return (
        <>
            <div className="confirm-dialog-modal" id="confirmModal">
                <div className="confirm-dialog-box">
                    <span className="close" onClick={closeModal}>&times;</span>
                    <div className="confirm-dialog-box-inner">
                        <h2>Do you really want to {props.confirmMsg}?</h2>
                        <p>Once you confirm you won’t be able to undo this action.</p>
                        <div className="btn-grp">
                            <button type="button" className="btn white-btn" onClick={closeModal}>{properties.CANCEL}</button>
                            <button type="button" className="btn blue-btn" onClick={confirmModal}>{properties.CONFIRM}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}