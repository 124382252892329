export const dynamicPMCHelper = (dynamicPaymentMethodConfigurations) => {
    const keyValuePairs = dynamicPaymentMethodConfigurations.split(';');
    const dynamicPaymentMethodConfigs = []
    keyValuePairs.forEach(pair => {
        const [key, val] = pair.split(':');
        const data = {
            "regionName": val.trim(),
            "pmc_id": key.trim()
        };
        dynamicPaymentMethodConfigs.push(data);
    })
    return dynamicPaymentMethodConfigs;
}